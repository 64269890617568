<template>
    <div class="dashboard-table-card-container ">
      <le-card width="100%" style="padding-bottom:20px" >
        <template>
          <div class="container">
            <div class="card-title" style="padding-top: 20px;">角色管理</div>
            <le-button txt="新增" class="le-button" @btnClick="addMenu()"  v-if="isAdd"  />
            <div class="table-box">
                <a-table 
                :columns="columns" 
                :data-source="dataSource"
                :loading="loading"
                :pagination="pageOptions"
                @change="handleTableChange"
                size="middle" >

                <span slot="customTitle">开播率 
                   <a-tooltip>
                  <template slot="title">
                    近3天直播过 记为有效
                  </template>
                    <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>
                  <div slot="identity" slot-scope="row" >
                      <div>{{row==1?'经纪人':'工会' }}</div>
                  </div>

                  <span slot="action" style="display: flex;justify-content:space-between;width: 100px;" slot-scope="row">
                    <le-button txt="编辑" width="40px" height="30px" fontSize="14px" @btnClick="clickEdit(row)"  v-if="isAdd" />
                    <a-popconfirm title="确定删除吗？" ok-text="确定" cancel-text="取消"   @confirm="toDelete(row)" v-if="isDelete" >
                      <le-button background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)" txt="删除" width="40px" height="30px" fontSize="14px"/>
                    </a-popconfirm>
                  </span>
              </a-table>
            </div>
          </div>
        </template>
      </le-card>
      <popEdit v-if="visible" :visible="visible"  @handleOk="handleOk"  @handleCancel="visible=false" :row="editRow"  />
    </div>
</template>

<script>

import columns from './columns'
import popEdit from './popEdit'
import tool from '@/utils/tool'
import {MixinList} from '@/common/mixin';
export default {
  components: {
    popEdit
  },
  data() {
    return {
      columns,
      dataUrl:'v2/adminRole/sel',
      delUrl:'v2/adminRole/del',
      visible: false,
      visibleLog: false,
      uid:'',
      editRow:{},
      isAdd:false,
      isDelete:false
    };
  },
  computed: {
  },
  mixins:[MixinList],
  mounted(){
    // if(this.$route.query.temp_uid){
    //   this.dataUrl='v1/user/list?temp_uid='
    // }
    // console.log(this.$route.query.temp_uid)

    let btn=window.localStorage.getItem('whiteBtns')
    if(btn){
      let btns=btn.split(',');
      if(btns.includes('auth-role-add')){
        this.isAdd = true;
      }
      if(btns.includes('auth-role-delete')){
        this.isDelete = true;
      }
    }
  },
  methods: {
    clickEdit(row){
      this.editRow=row
      this.visible = true;
    },
    handleOk(){
      this.visible = false;
      this.getData()
    },
    showLog(uid){
      console.log('uid',uid)
      this.uid=uid
      this.visibleLog=true
    },
    //新增菜单
    addMenu(){
      this.visible=true
      this.editRow={}
    }
  }
};
</script>

<style lang="less" scoped>
.dashboard-table-card-container {
.container{
  position: relative;
  .title{
    padding-top: 20px;
    margin-left: 30px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: flex-end;
    .num-box{
      font-size: 18px;
      margin-left: 11px;
      line-height: 32px;
    }
  }
  .le-button{
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .table-box{
    margin: 40px 30px;
  }
}
}

.login{
  cursor: pointer;
  &:hover{
    color: #008DF0;
  }
}

.name-box{
  display: flex;
  .avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    }
  }
}

@media screen and(max-width:750px) {
 .dashboard-table-card-container {
.container{
  .title{
    padding-top: 10px;
    margin-left: 20px;
    font-size: 20px;
    .num-box{
      font-size: 12px;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .le-button{
    position: absolute;
    top: 10px;
    right: 20px;
    width: 50px!important;
  }

  .table-box{
    margin: 20px;
    overflow-x:scroll ;
  }
}
}
}

</style>
