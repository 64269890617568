<template>
  <div class="dashboard-pop-edit-container">
    <a-modal
      :title="row.id?'编辑角色':'新增角色'"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="600px"
    >

<a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >

    <a-form-item label="角色名称">
      <a-input
        v-decorator="formDecorators.title"
      />
    </a-form-item>

    <a-form-item label="备注">
      <a-input
        max
        v-decorator="formDecorators.content"
      />
    </a-form-item>

    <a-form-item label="权限标识">
      <a-tree-select
        v-decorator="formDecorators.rules"
        style="width: 100%"
        :tree-data="list"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        placeholder="请选择权限规则"
        multiple
        tree-default-expand-all
      >
      </a-tree-select>


      <!-- <a-tree-select
        v-decorator="formDecorators.rules"
        style="width: 100%"
        :tree-data="list"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        placeholder="请选择权限规则"
        :show-checked-strategy="SHOW_PARENT"
        tree-checkable
      >
      </a-tree-select> -->

    </a-form-item>
    </a-form>
    </a-modal>
  </div>
</template>

<script>

import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
import tool from "@/utils/tool";
export default {
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this, { name: "coordinated" }),
      SHOW_PARENT,
      list:[]
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: ()=>{
        return {}
      },
    }
  },
  computed: {
    formDecorators() {
      let data = JSON.parse(JSON.stringify(this.row));

      return {
        title: [
          "title",
          {
            initialValue: data && data.title,
            rules: [{ required: true, message: "请填写角色名称" }],
            validateTrigger: ["blur"],
          },
        ],
        content: [
          "content",
          {
            initialValue: data && data.content,
          },
        ],
        rules: [
          "rules",
          {
            initialValue: data && data.rules,
            rules: [{ required: true, message: "请选择权限规则" }]
          },
        ]
      };
    },
  },
  created(){
    this.getMenus()
  },
  mounted() {
    
  },
  filters:{

  },
  methods: {
    // 获取菜单列表
    getMenus(){
      

      // this.$api.post(`/v2/adminRules/sel`)
      this.$api.post(`/v2/adminRules/get`)
            .then((resp) => {
              if (resp.code == 200) {
                console.log(resp)
                this.list=resp.data
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch((e) => {
              throw e;
            });
    },
    handleOk(){
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.row.id){
            values.id=this.row.id
          }else{
            values.id=''
          }
          // values.rules=values.rules.join(',')


          this.$api.post(`/v2/adminRole/add`, values)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message.success("添加成功");
                this.$emit("handleOk");
                tool.$emit("changeMenu");

              } else {
                this.$message.error(resp.message);
              }
              this.confirmLoading = false;
            })
            .catch((e) => {
              this.confirmLoading = false;
              throw e;
            });
        }else{
          this.confirmLoading = false;
        }
        })
    },
    handleCancel(){
      this.$emit('handleCancel')
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-pop-edit-container {
}

.list {
  display: flex;
  margin-bottom: 30px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
}

.label {
  width: 100px;
  font-size: 20px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 20px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
@media screen and(max-width:750px) {
  
.list {
  display: block;
  margin-bottom: 0px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
    margin-bottom: 15px;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 15px;
  }
}

.label {
  width: 100px;
  font-size: 16px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 16px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
}

</style>
